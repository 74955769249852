<template>
  <v-app>
    <div>
      <b-form @submit.stop.prevent="formOnsubmit">
        <MainForm
          :form="form"
          :error="error"
          :purpose="purpose"
          :mainSentenceShow="false"
          :genderShow="false"
          :citizenshipShow="false"
          :professionShow="false"
          :religionShow="false"
          :isMarriedShow="false"
          :letterType="5"
          citizenTitleButton="Pilih Ayah"
        >
          <template v-slot:after-opening-sentence>
            <b-form-group
              id="input-group-child_full_name"
              label="Nama Lengkap Anak:"
              label-for="input-child_full_name"
            >
              <b-form-input
                id="input-child_full_name"
                v-model="form.child_full_name"
                placeholder="Nama Lengkap Anak"
              ></b-form-input>
              <small class="text-danger">{{ error.child_full_name }}</small>
            </b-form-group>

            <b-form-group
              id="input-group-order"
              label="Anak Ke-:"
              label-for="input-order"
            >
              <b-form-input
                id="input-order"
                v-model="form.order"
                placeholder="Anak Ke-"
              ></b-form-input>
              <small class="text-danger">{{ error.order }}</small>
            </b-form-group>

            <b-form-group
              label="Jenis Kelamin Anak:"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio
                v-model="form.child_gender"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="male"
                >Laki-laki</b-form-radio
              >
              <b-form-radio
                v-model="form.child_gender"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="female"
                >Perempuan</b-form-radio
              >
              <small class="text-danger">{{ error.child_gender }}</small>
            </b-form-group>

            <label for="">Tempat, Tangal Lahir Anak:</label>
            <b-input-group>
              <div class="row mb-2">
                <div class="col">
                  <b-form-group
                    id="input-group-child_birth_place"
                    label=""
                    label-for="input-child_birth_place"
                  >
                    <b-form-input
                      id="input-child_birth_place"
                      v-model="form.child_birth_place"
                      placeholder="Tempat"
                    ></b-form-input>
                    <small class="text-danger">{{ error.child_birth_place }}</small>
                  </b-form-group>
                </div>
                <div class="col">
                  <v-dialog
                    ref="dialog"
                    v-model="modal2"
                    :return-value.sync="form.child_birth_date"
                    persistent
                    width="350px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <b-input-group>
                        <b-input-group-prepend>
                          <button
                            class="btn btn-secondary"
                            type="button"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <i class="fas fa-calendar"></i>
                          </button>
                        </b-input-group-prepend>
                        <b-form-input
                          id="input-trigger-modal-patient"
                          v-model="form.child_birth_date"
                          placeholder="Tanggal (YYYY-MM-DD)"
                          readonly
                        >
                        </b-form-input>
                        <small class="text-danger">{{ error.child_birth_date }}</small>
                      </b-input-group>
                    </template>

                    <v-date-picker
                      v-if="modal2"
                      v-model="form.child_birth_date"
                      locale="id"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(form.child_birth_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
              </div>
            </b-input-group>

            <b-form-group id="input-group-child_address">
              <label for="input-child_address">Alamat Anak: </label>
              <b-form-textarea
                id="input-child_address"
                v-model="form.child_address"
                placeholder="Alamat Anak"
                rows="4"
                max-rows="8"
              ></b-form-textarea>
              <small class="text-danger">{{ error.child_address }}</small>
            </b-form-group>

            <b-form-group
              id="input-group-birth_helper"
              label="Penolong Kelahiran:"
              label-for="input-birth_helper"
            >
              <b-form-input
                id="input-birth_helper"
                v-model="form.birth_helper"
                placeholder="Bidan / Dokter / Dukun"
              ></b-form-input>
              <small class="text-danger">{{ error.birth_helper }}</small>
            </b-form-group>

            <b-form-group id="input-group-birth_helper_address">
              <label for="input-birth_helper_address"
                >Alamat Penolong Kelahiran:
              </label>
              <b-form-textarea
                id="input-birth_helper_address"
                v-model="form.birth_helper_address"
                placeholder="Alamat Penolong Kelahiran"
                rows="4"
                max-rows="8"
              ></b-form-textarea>
              <small class="text-danger">{{
                error.birth_helper_address
              }}</small>
            </b-form-group>
          </template>

          <template v-slot:main>
            <div class="row mb-2">
              <div class="col">
                <button
                  class="btn btn-info btn-block"
                  type="button"
                  @click="$bvModal.show('modal-citizen-2')"
                >
                  Pilih Ibu
                </button>
              </div>
            </div>

            <b-form-group
              id="input-group-resource_name"
              label="Nama:"
              label-for="input-resource_name"
            >
              <b-form-input
                id="input-resource_name"
                v-model="formResource.resource_name"
                placeholder="Nama"
                readonly
              ></b-form-input>
              <small class="text-danger">{{ error.resource_name }}</small>
            </b-form-group>
            <b-form-group
              id="input-group-resource_id_card_number"
              label="NIK:"
              label-for="input-resource_id_card_number"
            >
              <b-form-input
                id="input-resource_id_card_number"
                v-model="formResource.resource_id_card_number"
                placeholder="NIK"
                readonly
              ></b-form-input>
              <small class="text-danger">{{
                error.resource_id_card_number
              }}</small>
            </b-form-group>
            <b-form-group
              id="input-group-resource_birt_place_date"
              label="Tempat / Tanggal Lahir:"
              label-for="input-resource_birt_place_date"
            >
              <b-form-input
                id="input-resource_birt_place_date"
                v-model="formResource.resource_birt_place_date"
                placeholder="Tempat / Tanggal Lahir"
                readonly
              ></b-form-input>
              <small class="text-danger">{{
                error.resource_birt_place_date
              }}</small>
            </b-form-group>
            <b-form-group id="input-group-resource_address">
              <label for="input-resource_address">Alamat: </label>
              <b-form-textarea
                id="input-resource_address"
                v-model="formResource.resource_address"
                placeholder="Alamat"
                rows="4"
                max-rows="8"
                readonly
              ></b-form-textarea>
              <small class="text-danger">{{ error.resource_address }}</small>
            </b-form-group>
          </template>
        </MainForm>
        <!-- Submit & Cancel button -->
        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="$router.push('/birth-certificate')"
        >
          Batal
        </b-button>
      </b-form>
    </div>

    <ModalCitizen
      @chooseCitizen2="chooseCitizen2"
      id="modal-citizen-2"
      returnEmit="chooseCitizen2"
    />
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import MainForm from "@/view/components/letters/Form.vue";
import ModalCitizen from "@/view/components/general/ModalCitizen.vue";

export default {
  props: {
    form: Object,
    formResource: Object,
    route: String,
    purpose: {
      type: String,
      default: "add",
    },
  },

  components: {
    MainForm,
    ModalCitizen,
  },

  data() {
    return {
      // Error
      error: {
        citizen_id: "",
        child_full_name: "",
        child_gender: "",
        child_address: "",
        child_birth_place: "",
        child_birth_date: "",
        child_order: "",
        // 
        name: "",
        id_card_number: "",
        gender: "",
        birt_place: "",
        birt_date: "",
        title: "",
        number: "",
        hamlet_id: "",
        purpose: "",
      },
      //   formResource: {
      //     resource_name: "",
      //     resource_id_card_number: "",
      //     resource_birt_place: "",
      //     resource_birt_date: "",
      //     resource_profession: "",
      //     resource_address: "",
      //     resource_birt_place_date: "",
      //   },
      modal2: false,
    };
  },

  methods: {
    chooseCitizen2(value) {
        console.log('value', this.value)
      this.$bvModal.hide("modal-citizen-2");
      this.form.resource_id = value.id;
      this.form.mother_id = value.id;
      this.formResource.mother_id = value.id;
      this.formResource.resource_name = value.name;
      this.formResource.resource_id_card_number = value.id_card_number;
      this.formResource.resource_birt_place = value.birt_place;
      this.formResource.resource_birt_date = value.birt_date;
      this.formResource.resource_profession = value.profession;
      this.formResource.resource_address = value.address;
      this.formResource.resource_birt_place_date =
        value.birt_place + ", " + value.birt_date;

      console.log("formresource", this.formResource);
      console.log("formresource", this.formResource);
    },
    async formOnsubmit() {
        console.log('form', this.form)
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/birth-certificate");
      }
    },
  },
  mounted() {
    // if(this.purpose == "edit"){
    //     this.form
    // }
  },
};
</script>

<style>
</style>